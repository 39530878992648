import { useEffect } from 'react';

const VisitorTracker = () => {
  useEffect(() => {
    const trackVisit = async () => {
      try {
        const response = await fetch('https://www.gmc-works.com/businessclicker/', {
          method: 'GET',
          credentials: 'include', // This is important for sessions
        });
        
        if (!response.ok) {
          console.error('Failed to track visit');
        }
      } catch (error) {
        console.error('Error tracking visit:', error);
      }
    };

    trackVisit();
  }, []);

  return (
    <span></span>
  );
};

export default VisitorTracker; 